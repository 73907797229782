import { UserIdSlugSnSchema } from "./types";
import { route } from "react-router-typesafe-routes/dom";
import { useEnv } from "shared/dist/util/env";
import { z } from "zod";
import { zod } from "react-router-typesafe-routes/zod";

export const adminRoutes = {
  LOGIN: route("login", {}),
  LOGOUT: route("logout", {}),
  INVITATIONS: route("invitations", {}),
  NOTIFICATIONS: route("notifications", {}),
  MESSAGE_BLAST: route(
    "message-blast",
    { state: { preloadUsers: zod(z.array(UserIdSlugSnSchema).nullish()) } },
    {
      PUSH_BLAST: route("via-push", {}),
      BLAST_HISTORY: route("history", {}),
      NEW_USERS: route("new-users", {}),
    }
  ),
  FEATURE_FLAGS: route("feature-flags", {}),
  EVENTS: route(
    "events",
    {},
    {
      DETAIL: route("detail/:event_id", {}),
    }
  ),
  USERS: route(
    "users",
    {},
    {
      DETAIL: route(
        "detail/:slug",
        {},
        {
          DISCOVERY: route("discovery", {}),
          PHOTOS: route("photos", {}),
          DELETE: route("delete", {}),
          RELATIONSHIPS: route("relationships", {}),
          VOUCHES: route("vouches", {}),
          ID_MEDIA: route("id-media", {}),
        }
      ),
    }
  ),
  PROFILE_GROUPING: route("grouping", {}, { DETAIL: route("detail/:slug", {}) }),
  MEDIA: route(
    "media",
    {},
    {
      EDIT: route("edit/:media_id", {}),
      DETAIL: route("detail/:media_id", {}),
    }
  ),
  RELATIONSHIPS: route("relationships", {}, { FILTER: route("id/:filter", {}) }),
  STATS: route("stats", {}),
  DISCOVERY_STATS: route(
    "discovery-stats",
    {},
    {
      DEBUGGER: route("debugger", {}, { DETAIL_QUERY: route("detail-query", {}) }),
    }
  ),
  DISCOVERY_USER: route(
    "discovery-user",
    {},
    // {
    //   DEBUGGER: route("debugger", {}, { DETAIL_QUERY: route("detail-query", {}) }),
    {
      DETAILS: route(":slug", {}),
    }
    // }
  ),
  NUCLEAR_OPTIONS: route("nuclear-options", {}),
  ROOT: route("", {}),
};

export type Size = "1280" | "128" | "256" | "avatar" | "md" | "lg" | "full" | "thumb";
export const useMkImgAdminUrl = () => {
  const origin = useEnv("REACT_APP_BASE_URI");
  return (mediaId: string, size?: Size) => `${origin}_mi/${mediaId}?size=${size ?? "md"}`;
};
