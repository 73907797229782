import {
  AdminUserCountQuery,
  Onboarding_Statuses_Enum,
  useAdminUserCountQuery,
  useAdminUserCountSimpleQuery,
} from "../__generated__/apollo-hooks";
import { H2, H3 } from "shared-web-react/dist/widgets/text";
import { exampleLocations, toGeo } from "shared/dist/util/loc";

import { DateTime } from "luxon";
import React from "react";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import clsx from "clsx";

function NullableTd({
  loading,
  children,
}: {
  loading?: boolean;
  children: React.ReactNode;
}): JSX.Element {
  return <td>{loading ? <Spinner /> : children}</td>;
}

function UserStatsRow({
  label,
  field,
  cols,
}: {
  label: string;
  field: keyof Omit<AdminUserCountQuery, "__typename">;
  cols: Array<ReturnType<typeof useAdminUserCountQuery>>;
}): JSX.Element {
  return (
    <tr>
      <td>{label}</td>
      {cols.map((c, idx) => (
        <NullableTd loading={c.loading} key={idx}>
          {c.data?.[field]?.aggregate?.count ?? ""}
        </NullableTd>
      ))}
    </tr>
  );
}

const dates = {
  yesterday: DateTime.now().minus({ days: 1 }).toISO(),
  one_week_ago: DateTime.now().minus({ weeks: 1 }).toISO(),
  two_weeks_ago: DateTime.now().minus({ weeks: 2 }).toISO(),
  one_month_ago: DateTime.now().minus({ months: 1 }).toISO(),
  two_months_ago: DateTime.now().minus({ months: 2 }).toISO(),
};

type SimpleQueryFilter = NonNullable<
  Parameters<typeof useAdminUserCountSimpleQuery>[0]["variables"]
>["filter"];

function SimpleUserQuery({
  label,
  filter,
}: {
  label: React.ReactNode;
  filter: SimpleQueryFilter;
}): JSX.Element {
  const query = useAdminUserCountSimpleQuery({ variables: { filter } });
  return (
    <>
      <span className={clsx("mr-1")}>{label}</span>
      <SimpleUserStat {...{ query }} />
    </>
  );
}

function SimpleUserStat({
  query,
}: {
  query: ReturnType<typeof useAdminUserCountSimpleQuery>;
}): JSX.Element {
  return query.loading ? (
    <Spinner />
  ) : (
    <span>{query?.data?.users_aggregate?.aggregate?.count ?? "<null>"}</span>
  );
}

export function Stats(): JSX.Element {
  const allUsersQuery = useAdminUserCountQuery({
    variables: {
      ...dates,
      filter: {},
    },
  });
  const onboardedUsersQuery = useAdminUserCountQuery({
    variables: {
      ...dates,
      filter: { onboarding_status: { _eq: Onboarding_Statuses_Enum.V2_20LiveUser } },
    },
  });
  const totalUsers = useAdminUserCountSimpleQuery({ variables: { filter: {} } });
  // const anyLoading =
  //   allUsersQuery.loading ||
  //   onboardedUsersQuery.loading ||
  //   invitedButNotOnboardedUsersQuery.loading;
  return (
    <div className="pt-4 space-y-2">
      <H2>stats</H2>
      <p>
        Total Users: <SimpleUserStat query={totalUsers} />
      </p>
      <table className="table inline-block table-zebra table-sm min-w-0">
        <thead>
          <tr>
            <th></th>
            <th>all users</th>
            <th>onboarded users</th>
          </tr>
        </thead>
        <tbody>
          <UserStatsRow
            label="All Time"
            field="all_time"
            cols={[allUsersQuery, onboardedUsersQuery]}
          />
          <UserStatsRow
            label="Yesterday"
            field="yesterday"
            cols={[allUsersQuery, onboardedUsersQuery]}
          />
          <UserStatsRow
            label="Last week"
            field="last_week"
            cols={[allUsersQuery, onboardedUsersQuery]}
          />
          <UserStatsRow
            label="This Month"
            field="this_month"
            cols={[allUsersQuery, onboardedUsersQuery]}
          />
          <UserStatsRow
            label="Last Month"
            field="last_month"
            cols={[allUsersQuery, onboardedUsersQuery]}
          />
        </tbody>
      </table>
      <H3>Other Stats</H3>
      <div className={clsx("grid grid-cols-2 w-fit gap-x-2 gap-y-4")}>
        <SimpleUserQuery
          label="Onboarded Users who are unverified"
          filter={{
            _not: { identity_verifications: { success: { _eq: true } } },
            _or: [{ last_location: { _is_null: true } }, { gender_id: { _is_null: true } }],
            onboarding_status: { _eq: Onboarding_Statuses_Enum.V2_20LiveUser },
          }}
        />
        <SimpleUserQuery
          label="Onboarded Users with out gender or location"
          filter={{
            _or: [{ last_location: { _is_null: true } }, { gender_id: { _is_null: true } }],
            onboarding_status: { _eq: Onboarding_Statuses_Enum.V2_20LiveUser },
          }}
        />
        <SimpleUserQuery
          label="Verified and Onboarded Users with out gender or location"
          filter={{
            identity_verifications: { success: { _eq: true } },
            _or: [{ last_location: { _is_null: true } }, { gender_id: { _is_null: true } }],
            onboarding_status: { _eq: Onboarding_Statuses_Enum.V2_20LiveUser },
          }}
        />
        <SimpleUserQuery
          label="Verified & Onboarded Users w/in 100 miles of LA"
          filter={{
            identity_verifications: { success: { _eq: true } },
            last_location: {
              _st_d_within: { distance: 160000, from: toGeo(exampleLocations.lax) },
            },
            onboarding_status: { _eq: Onboarding_Statuses_Enum.V2_20LiveUser },
          }}
        />
        <SimpleUserQuery
          label="Verified & Onboarded Users w/in 100 miles of Miami (test)"
          filter={{
            identity_verifications: { success: { _eq: true } },
            last_location: {
              _st_d_within: { distance: 160000, from: toGeo(exampleLocations.brickell) },
            },
            onboarding_status: { _eq: Onboarding_Statuses_Enum.V2_20LiveUser },
          }}
        />
        <SimpleUserQuery
          label="Verified & Onboarded Users with out gender w/in 100 miles of LA"
          filter={{
            identity_verifications: { success: { _eq: true } },
            last_location: {
              _st_d_within: { distance: 160000, from: toGeo(exampleLocations.lax) },
            },
            gender_id: { _is_null: true },
            onboarding_status: { _eq: Onboarding_Statuses_Enum.V2_20LiveUser },
          }}
        />
        <SimpleUserQuery
          label="Verified & Onboarded Users with out gender w/in 100 miles of Miami (test)"
          filter={{
            identity_verifications: { success: { _eq: true } },
            last_location: {
              _st_d_within: { distance: 160000, from: toGeo(exampleLocations.brickell) },
            },
            gender_id: { _is_null: true },
            onboarding_status: { _eq: Onboarding_Statuses_Enum.V2_20LiveUser },
          }}
        />
        <SimpleUserQuery
          label="Verified & Onboarded Users with out gender"
          filter={{
            identity_verifications: { success: { _eq: true } },
            gender_id: { _is_null: true },
            onboarding_status: { _eq: Onboarding_Statuses_Enum.V2_20LiveUser },
          }}
        />
        <SimpleUserQuery
          label="Verified & Onboarded Users with out location"
          filter={{
            identity_verifications: { success: { _eq: true } },
            last_location: { _is_null: true },
            onboarding_status: { _eq: Onboarding_Statuses_Enum.V2_20LiveUser },
          }}
        />
        <SimpleUserQuery
          label="Onboarded Users with out gender"
          filter={{
            gender_id: { _is_null: true },
            onboarding_status: { _eq: Onboarding_Statuses_Enum.V2_20LiveUser },
          }}
        />
        <SimpleUserQuery
          label="Onboarded Users with out location"
          filter={{
            last_location: { _is_null: true },
            onboarding_status: { _eq: Onboarding_Statuses_Enum.V2_20LiveUser },
          }}
        />
        <SimpleUserQuery
          label="Users with a bio >= 5 chars"
          filter={{ bio: { _iregex: "^.{5,}$" } }}
        />
        <SimpleUserQuery
          label="Users with one or more image"
          filter={{
            media_with_assets_aggregate: {
              count: { filter: { exclude_from_bio: { _eq: false } }, predicate: { _gte: 1 } },
            },
          }}
        />
        <SimpleUserQuery
          label="Users with six or more images"
          filter={{
            media_with_assets_aggregate: {
              count: { filter: { exclude_from_bio: { _eq: false } }, predicate: { _gte: 6 } },
            },
          }}
        />
        <SimpleUserQuery
          label="Users with at least one friend"
          filter={{
            all_my_relationships_aggregate: {
              count: {
                filter: { type: { _eq: "friend" }, status: { _eq: "accepted" } },
                predicate: { _gte: 1 },
              },
            },
          }}
        />
      </div>
    </div>
  );
}
