import {
  useRemoveAllManualVerificationsMutation,
  useSetManyVerifiedMutation,
  useUnverifiedUserIdsQuery,
} from "../__generated__/apollo-hooks";

import React from "react";
import { classNames } from "shared/dist/util";
import { useMyId } from "shared/dist/auth-data";

export function NuclearOptions(): JSX.Element {
  const { data: unverifiedUserData, refetch } = useUnverifiedUserIdsQuery();
  const my_id = useMyId();
  const [clearAllVerifications] = useRemoveAllManualVerificationsMutation();
  const [verifyAllMutation] = useSetManyVerifiedMutation();
  const verifyAll = React.useCallback(async () => {
    if (!confirm("Are you sure you want to verify all dev users?  this is irreversible")) {
      return;
    }
    const unverified = await refetch();
    const inserts = unverified.data?.admin_user_summaries?.map((user) => ({
      user_id: user.id,
      source: "manual",
      success: true,
      data: { admin_user: my_id, extra: "done from admin panel in batch add" },
    }));
    if (!inserts) {
      alert("no unverified users");
      return;
    }
    const res = await verifyAllMutation({
      variables: { objects: inserts },
      refetchQueries: ["AdminUserList"],
    });
    alert(`Verified ${res.data?.insert_identity_verifications?.affected_rows} users`);
    refetch();
  }, [refetch, verifyAllMutation]);
  const removeAll = React.useCallback(async () => {
    if (
      !confirm("Are you sure you want to remove all manual verifications?  this is irreversible")
    ) {
      return;
    }
    const res = await clearAllVerifications({
      refetchQueries: ["AdminUserList", "UnverifiedUserIds"],
    });
    alert(`Cleared ${res.data?.delete_identity_verifications?.affected_rows} verifications`);
  }, [clearAllVerifications]);
  return (
    <div className="space-y-2 py-8 flex flex-col justify-stretch max-h-screen overflow-hidden relative">
      <div>
        <button onClick={verifyAll} className={classNames("btn btn-lg btn-warning")}>
          Verfiy All Dev Users ({unverifiedUserData?.admin_user_summaries?.length ?? ""})
        </button>
      </div>
      <div>
        <button onClick={removeAll} className={classNames("btn btn-lg btn-warning")}>
          Remove all manual verifications for dev users
        </button>
      </div>
    </div>
  );
}
