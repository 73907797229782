import {
  AdminIdVerificationDataQuery,
  useAdminIdVerificationDataQuery,
} from "../__generated__/apollo-hooks";

import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { adminRoutes } from "../util/admin-routes";
import { useDateStr } from "../util/widgets";
import { useMyRoles } from "shared/dist/auth-data";
import { useTypedParams } from "react-router-typesafe-routes/dom";

function AdminIdMediaItem({
  idMediaItem,
}: {
  idMediaItem: AdminIdVerificationDataQuery["identity_verifications"][0]["identity_verification_media"][0];
}): JSX.Element {
  const url = idMediaItem.presigned_url?.presigned_url;
  if (!url) return <span>missing url</span>;
  if (idMediaItem.content_type?.toLowerCase?.()?.endsWith?.("pdf")) {
    return (
      <a href={url} className="btn btn-sm" download>
        pdf report
      </a>
    );
  }
  return (
    <div className="flex flex-row justify-start gap-2 items-center">
      <img src={url} className="h-32 mr-2" /> {idMediaItem.document_type}
    </div>
  );
}

function AdminIdVerification({
  idData,
}: {
  idData: AdminIdVerificationDataQuery["identity_verifications"][0];
}): JSX.Element {
  const createdStr = useDateStr(idData.created_at);
  return (
    <div>
      <div>birthday: {idData.birthday}</div>
      <div>id type: {idData.id_class}</div>
      <div>success: {idData.success ? "yes" : "no"}</div>
      <div>revoked: {idData.user_revoked ? "yes" : "no"}</div>
      <div>created_at {createdStr}</div>
      <div className="space-y-2">
        media:
        {idData?.identity_verification_media?.map?.((m) => (
          <div key={m.id}>
            <AdminIdMediaItem idMediaItem={m} />
          </div>
        ))}
      </div>
      <div className="divider" />
    </div>
  );
}

const superAdminContext = { headers: { "x-hasura-role": "super_admin_user" } };

export function AdminIdMedia(): JSX.Element {
  const isSuperAdmin = useMyRoles()?.includes("super_admin_user");
  const slug = useTypedParams(adminRoutes.USERS.DETAIL.ID_MEDIA).slug;
  const { data, loading } = useAdminIdVerificationDataQuery({
    context: superAdminContext,
    variables: { slug },
    skip: !isSuperAdmin,
  });

  if (!isSuperAdmin) return <div>not super admin</div>;
  if (!data && loading) return <SpinnerCentered className="py-8" />;
  return (
    <div className="space-y-4">
      {data?.identity_verifications?.map((idData) => (
        <AdminIdVerification key={idData.id} idData={idData} />
      ))}
    </div>
  );
}
