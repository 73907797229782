import { AdminTab, AdminUserPicker, AdminUserTd, DateTd, IdSpan } from "../util/widgets";
import { H1, H2, H3 } from "shared-web-react/dist/widgets/text";
import { P, match } from "ts-pattern";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared-web-react/dist/widgets/floating-ui/tooltip";
import { faCheck, faXmark, faXmarkCircle } from "@fortawesome/pro-solid-svg-icons";
import { filterNulls, uniqBy } from "shared/dist/util";
import {
  useAdminBlastHistoryQuery,
  useAdminSendSmsBlastMutation,
  useSendBlastMessageMutation,
  useUsersCreatedInRangeQuery,
} from "../__generated__/apollo-hooks";

import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Outlet } from "react-router-dom";
import { Paginator } from "shared-web-react/dist/widgets/paginator";
import React from "react";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import { UserIdSlugSn } from "../util/types";
import { adminRoutes } from "../util/admin-routes";
import clsx from "clsx";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useTypedState } from "react-router-typesafe-routes/dom";

export function AdminMessageBlast(): JSX.Element {
  return (
    <div>
      <H1 className="py-8">User Messaging</H1>
      <div className={clsx("tabs-lg tabs-lifted flex-0 tabs")}>
        <AdminTab end to={adminRoutes.MESSAGE_BLAST.buildPath({})}>
          Send bulk user SMS blasts
        </AdminTab>
        <AdminTab end to={adminRoutes.MESSAGE_BLAST.PUSH_BLAST.buildPath({})}>
          Send bulk user Push Notifications
        </AdminTab>
        <AdminTab end to={adminRoutes.MESSAGE_BLAST.BLAST_HISTORY.buildPath({})}>
          user SMS blast history
        </AdminTab>
        <AdminTab to={adminRoutes.MESSAGE_BLAST.NEW_USERS.buildPath({})}>
          new users thread messages
        </AdminTab>
      </div>
      <Outlet />
    </div>
  );
}

export function AdminBlastHistory(): JSX.Element {
  const [filter, setFilter] = React.useState("");
  const limit = 50;
  const [page, setPage] = React.useState(0);
  const { data, loading, refetch } = useAdminBlastHistoryQuery({
    variables: {
      limit,
      offset: page * limit,
      filter: `%${filter}%`,
    },
  });
  const count = data?.admin_sms_blasts?.length ?? 0;
  const pageCount = count ? Math.ceil(count / limit) : 0;
  return (
    <div>
      <div className="space-y-2 pt-4 flex flex-col justify-stretch max-h-screen overflow-hidden relative">
        <div className="flex flex-row justify-start items-center gap-2">
          Total: {count}
          <Paginator {...{ page, setPage, pageCount }} />
          <div className="form-control">
            <div className="join">
              <input
                type="text"
                placeholder="Search…"
                className="UserList join-item input input-bordered"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
              <button onClick={() => setFilter("")} className="join-item btn">
                reset
              </button>
              <button onClick={() => refetch()} className="join-item btn">
                reload
              </button>
            </div>
          </div>
          {loading && <Spinner />}
        </div>
        <div className="overflow-auto w-full ">
          <table className="table w-full max-w-full min-w-min overflow-auto table-sm">
            <thead>
              <tr>
                <th>sent successfully</th>
                <th>Blast ID</th>
                <th>Date</th>
                <th>to user</th>
                <th>other notes</th>
              </tr>
            </thead>
            <tbody>
              {data?.admin_sms_blasts?.map((blast, idx) => (
                <tr key={idx}>
                  <td>
                    <FontAwesomeIcon
                      icon={blast.sent_successfully ? faCheck : faXmark}
                      className={blast.sent_successfully ? "text-green-600" : "text-error"}
                    />
                  </td>
                  <td>
                    <IdSpan id={blast.id} />
                  </td>
                  <DateTd date={blast.created_at} />
                  <AdminUserTd slug={blast.admin_user_summary?.slug} />
                  <td>{blast.content}</td>
                  <td>{blast.notes}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export function AdminOpenMessageBlast(): JSX.Element {
  const [sendMutation, { loading: sendMutationLoading }] = useAdminSendSmsBlastMutation();
  const preloadedUsers = useTypedState(adminRoutes.MESSAGE_BLAST)?.preloadUsers;
  const [selectedUsers, setSelectedUsers] = React.useState<Array<UserIdSlugSn>>(
    preloadedUsers ?? []
  );
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);
  const confirm = useConfirm();
  const send = React.useCallback(async () => {
    if (!selectedUsers.length || !message.length) return;
    const result = await sendMutation({
      variables: { content: message, user_ids: selectedUsers.map((u) => u.id) },
    });
    match(result.data?.lm_sms_blast)
      .with({ __typename: "L_Blast_Success" }, (r) => {
        setSelectedUsers([]);
        setSuccess(`Sent to ${r.user_ids.length} users`);
      })
      .with({ __typename: "L_Blast_Partial_Error" }, (r) => {
        setSuccess(`Sent to ${r.sent_user_ids.length} users`);
        setError(`failed sending to ${r.failed_user_ids.length} users`);
        setSelectedUsers((users) => users.filter((u) => r.failed_user_ids.includes(u.id)));
      })
      .with({ __typename: "L_Simple_Response_Error" }, (r) => {
        setError(r.human_readable_error);
        setSuccess(null);
      })
      .with(P.nullish, () => {
        setError("No response from the server");
        setSuccess(null);
      })
      .exhaustive();
  }, [setSelectedUsers, sendMutation, setError, setSuccess, selectedUsers, message]);
  return (
    <div>
      <div className="divider" />
      <div
        className={clsx(
          "grid px-8  gap-3 justify-start justify-items-start  grid-cols-[min-content_80ch_min-content]"
        )}
      >
        <H3>1) Add Users:</H3>
        <H3>2) Draft Message:</H3>
        <H3>3) Send:</H3>
        <div>Users:</div>
        <div>Content:</div>
        <div className="flex flex-col justify-start items-start gap-2 row-span-2">
          <SpinnerButton
            className="btn btn-warning btn-lg"
            disabled={sendMutationLoading || !selectedUsers.length || !message.length}
            onClickWrapped={async () =>
              confirm({
                title: "Send messages?",
                content: `There is no going back`,
                onOk: send,
              })
            }
          >
            Send Messages
          </SpinnerButton>
          {error && <div className="text-error">{error}</div>}
          {success && <div className="">{success}</div>}
        </div>
        <div>
          <div>
            <AdminUserPicker
              onSelect={(u) => u && setSelectedUsers((prev) => uniqBy([u, ...prev], (u) => u.id))}
            />
          </div>
          <ul className="min-w-[32ch]">
            {selectedUsers.map((user) => (
              <li className="flex flex-row items-center justify-start max-w-[30ch]">
                <button
                  onClick={() => setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id))}
                >
                  <FontAwesomeIcon icon={faXmarkCircle} className="mr-2 text-error" />
                </button>
                <div className="text-ellipsis whitespace-nowrap w-full overflow-hidden">{`${user.screenName} @${user.slug}`}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full">
          <textarea
            className="textarea textarea-bordered w-[80ch] max-w-full"
            value={message}
            rows={4}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export function AdminNewUserMessageBlast(): JSX.Element {
  const [startDate, setStartDate] = React.useState<DateTime>(DateTime.now().minus({ months: 1 }));
  const [endDate, setEndDate] = React.useState<DateTime>(DateTime.now());

  const { data, loading, refetch } = useUsersCreatedInRangeQuery({
    variables: { start: startDate?.toISO()!, end: endDate?.toISO()! },
    skip: !startDate || !endDate,
  });
  const [extraLoading, setExtraLoading] = React.useState(false);
  const [mutate, mutationResults] = useSendBlastMessageMutation();
  const [status, setStatus] = React.useState<string | null>(null);
  const sendBlast = React.useCallback(async () => {
    if (loading) return;
    try {
      setExtraLoading(true);
      const userIds = data?.raw_users?.map((u) => u.id);
      if (!userIds) return;
      const usersPerBatch = 25;

      const userBatches = Array.from(
        { length: Math.ceil(userIds.length / usersPerBatch) },
        (_, i) => userIds.slice(i * usersPerBatch, i * usersPerBatch + usersPerBatch)
      );
      await Promise.all(
        userBatches.map((batch, idx) =>
          mutate({ variables: { user_ids: filterNulls(batch) } }).then(() =>
            setStatus(`Sent batch ${idx + 1} of ${userBatches.length}`)
          )
        )
      );

      // Example usage
    } catch (e) {
      console.log("🚀 ~ file: admin-message-blast.tsx:32 ~ sendBlast ~ e:", e);
    } finally {
      setExtraLoading(false);
      setStatus(null);
      refetch();
    }
  }, [data, loading, extraLoading, setStatus, mutate, refetch]);
  const anyLoading = loading || extraLoading || mutationResults.loading;
  return (
    <div className={clsx("max-h-screen space-y-4")}>
      <H1>User Message Blast</H1>
      <div className="flex flex-row gap-3 items-center justify-start">
        <div className="flex-0 form-control">
          <label className="join whitespace-nowrap">
            <span className={clsx("join-item")}>start date</span>
            <input
              className="join-item input input-bordered w-full focus:outline-none min-w-0  !rounded-r-none"
              type="date"
              max={DateTime.now().minus({ days: 1 }).toISODate() ?? undefined}
              disabled={anyLoading}
              value={startDate.toISODate() ?? undefined}
              onChange={(e) => {
                const dt = e.target.value ? DateTime.fromISO(e.target.value) : null;
                dt && setStartDate(dt);
              }}
            />
          </label>
        </div>
        <div className="flex-0 form-control whitespace-nowrap items-center flex">
          <label className="join">
            <span className={clsx("join-item")}>End Date:</span>
            <input
              className="join-item input input-bordered w-full focus:outline-none min-w-0  !rounded-r-none"
              type="date"
              min={startDate.plus({ days: 1 }).toISODate() ?? undefined}
              disabled={anyLoading}
              value={endDate.toISODate() ?? undefined}
              onChange={(e) => {
                const dt = e.target.value ? DateTime.fromISO(e.target.value) : null;
                dt && setEndDate(dt);
              }}
            />
          </label>
        </div>
      </div>
      <div className={clsx("relative")}>
        <Tooltip>
          <TooltipTrigger>
            <SpinnerButton
              onClickWrapped={sendBlast}
              disabled={anyLoading || !data?.raw_users?.length}
              className="btn btn-primary btn-lg"
            >
              Send Message to {data?.raw_users?.length ?? 0} Users
            </SpinnerButton>
          </TooltipTrigger>
          {data?.thread_message_blasts?.[0]?.content && (
            <TooltipContent>{data?.thread_message_blasts?.[0]?.content}</TooltipContent>
          )}
        </Tooltip>
        {status}
      </div>

      <div className={clsx("overflow-auto")}>
        <H2>Users that have not received a blast message:</H2>
        {loading && !data?.raw_users && (
          <div className={clsx("py-6")}>
            <SpinnerCentered />
          </div>
        )}
        {data?.raw_users && (
          <table className="table w-full table-sm">
            <thead>
              <tr>
                <th>user</th>
                <th>phone</th>
                <th>created at</th>
                <th>id</th>
              </tr>
            </thead>
            <tbody>
              {data.raw_users.map(
                (user) =>
                  user.id &&
                  user.slug && (
                    <tr key={user.id}>
                      <AdminUserTd slug={user.slug} />
                      <td>{user.phone_number}</td>
                      <DateTd date={user.created_at} />
                      <td>
                        <IdSpan id={user.id} />
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
