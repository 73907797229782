import {
  AdminSendPushBlastMutationVariables,
  useAdminSendPushBlastMutation,
  useAdminUserHasPushTokenQuery,
} from "../../__generated__/apollo-hooks";
import { P, match } from "ts-pattern";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared-web-react/dist/widgets/floating-ui/tooltip";
import { faCheck, faXmark, faXmarkCircle } from "@fortawesome/pro-solid-svg-icons";
import { stringify, uniqBy } from "shared/dist/util";

import { AdminUserPicker } from "../../util/widgets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H3 } from "shared-web-react/dist/widgets/text";
import React from "react";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import { UserIdSlugSn } from "../../util/types";
import { adminRoutes } from "../../util/admin-routes";
import clsx from "clsx";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useTypedState } from "react-router-typesafe-routes/dom";
import { z } from "zod";

type PushData = { users: Array<UserIdSlugSn> } & Pick<
  AdminSendPushBlastMutationVariables,
  "body" | "title" | "url" | "image_url"
>;
function useSendPush(
  setSuccess: (s: string | null) => unknown,
  setError: (s: string | null) => unknown,
  setUsers: (u: UserIdSlugSn[]) => unknown
) {
  const [sendMutation, { loading }] = useAdminSendPushBlastMutation();
  const send = React.useCallback(
    async ({ body, title, url, image_url, users }: PushData) => {
      console.log("🚀 - file: admin-push-blast.tsx:74 - send - selectedUsers:", users);
      if (!users.length || !body.length) return;
      const parsedImageUrl = match(z.string().url().safeParse(image_url))
        .with({ data: P.select() }, (s) => s)
        .otherwise(() => null);
      const result = await sendMutation({
        variables: {
          body,
          title,
          user_ids: users.map((u) => u.id),
          ...(url?.length ? { url } : undefined),
          ...(parsedImageUrl ? { image_url: parsedImageUrl } : undefined),
        },
      });
      match(result.data?.lm_push_blast)
        .with({ __typename: "L_Blast_Success" }, (r) => {
          setUsers([]);
          setSuccess(`Sent to ${r.user_ids.length} users`);
        })
        .with({ __typename: "L_Blast_Partial_Error" }, (r) => {
          setSuccess(`Sent to ${r.sent_user_ids.length} users`);
          setError(`failed sending to ${r.failed_user_ids.length} users`);
          setUsers(users.filter((u) => r.failed_user_ids.includes(u.id)));
        })
        .with({ __typename: "L_Simple_Response_Error" }, (r) => {
          setError(r.human_readable_error);
          setSuccess(null);
        })
        .with(P.nullish, () => {
          setError("No response from the server");
          setSuccess(null);
        })
        .exhaustive();
    },
    [setUsers, sendMutation, setError, setSuccess]
  );
  return { send, loading };
}

export function AdminOpenPushNotificationBlast(): JSX.Element {
  const confirm = useConfirm();
  const preloadedUsers = useTypedState(adminRoutes.MESSAGE_BLAST)?.preloadUsers;
  const [pushData, setPushData] = React.useState<PushData>({
    users: preloadedUsers ?? [],
    body: "",
    title: "",
    url: "",
    image_url: "",
  });
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);
  const { send, loading } = useSendPush(setSuccess, setError, (u) =>
    setPushData((p) => ({ ...p, users: u }))
  );
  return (
    <div>
      <div className="divider" />
      <div
        className={clsx(
          "grid px-8  gap-3 justify-start justify-items-start  grid-cols-[min-content_80ch_min-content]"
        )}
      >
        <H3>1) Add Users:</H3>
        <H3>2) Draft Message:</H3>
        <H3>3) Send:</H3>
        <div>Users:</div>
        <div>Title:</div>
        <div className="flex flex-col justify-start items-start gap-2 row-span-2">
          <SpinnerButton
            loading={loading}
            className="btn btn-warning btn-lg"
            disabled={loading || !pushData.users.length || !pushData.body.length}
            onClick={() =>
              confirm({
                title: "Send messages?",
                content: `There is no going back`,
                onOk: () => send({ ...pushData }),
              })
            }
          >
            Send Messages
          </SpinnerButton>
          {error && <div className="text-error">{error}</div>}
          {success && <div className="">{success}</div>}
        </div>
        <div>
          <div>
            <AdminUserPicker
              onSelect={(u) =>
                u &&
                setPushData((prev) => ({ ...prev, users: uniqBy([u, ...prev.users], (u) => u.id) }))
              }
            />
          </div>
          <ul className="min-w-[32ch]">
            {pushData.users.map((user) => (
              <li key={user.id} className="flex flex-row items-start justify-start max-w-[30ch]">
                <UserPushTokenCheck
                  user={user}
                  onUserPushCheck={(_) => {}}
                  onRemoveUser={() =>
                    setPushData((prev) => ({
                      ...prev,
                      users: pushData.users.filter((u) => u.id !== user.id),
                    }))
                  }
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="w-[80ch] flex-col justify-start items-stretch gap-2">
          <input
            className="input input-bordered w-full"
            value={pushData.title}
            onChange={(e) => setPushData((p) => ({ ...p, title: e.target.value }))}
          />
          <div>Content:</div>
          <textarea
            className="textarea textarea-bordered w-full max-w-full"
            value={pushData.body}
            rows={4}
            onChange={(e) => setPushData((p) => ({ ...p, body: e.target.value }))}
          />
          <div className="opacity-50">Notification Image (exact URL) - not working yet</div>
          <input
            className="input input-bordered w-full"
            disabled
            value={pushData.image_url ?? ""}
            placeholder="https://exmaple.com/image.png"
            onChange={(e) => setPushData((p) => ({ ...p, image_url: e.target.value }))}
          />
          <div>Notification Deep Link:</div>
          <div className="join input input-bordered w-full flex-row flex justify-start items-center">
            <label className="join-item opacity-50">https://candid.bio/</label>
            <input
              className=""
              value={pushData.url ?? ""}
              placeholder="events/123"
              onChange={(e) => setPushData((p) => ({ ...p, url: e.target.value }))}
            />
          </div>
        </div>
      </div>
      <div>
        <pre>{stringify(pushData, { pp: true })}</pre>
      </div>
    </div>
  );
}

function UserPushTokenCheck({
  user,
  onUserPushCheck,
  onRemoveUser,
}: {
  onRemoveUser: () => unknown;
  onUserPushCheck: (hasPushToken: boolean) => unknown;
  user: { id: string; slug: string; screenName: string };
}) {
  const { data, loading } = useAdminUserHasPushTokenQuery({
    variables: { user_id: user.id },
    fetchPolicy: "cache-first",
  });
  const hasToken = !!data?.user_push_notification_tokens_by_pk?.updated_at;
  React.useEffect(() => {
    if (!data) return;
    if (data.user_push_notification_tokens_by_pk)
      onUserPushCheck(!!data.user_push_notification_tokens_by_pk?.updated_at);
  }, [data]);
  return (
    <Tooltip>
      <TooltipTrigger className="max-w-full w-full">
        <div
          className={clsx(
            "flex flex-row items-center justify-between whitespace-nowrap overflow-hidden",
            hasToken ? "" : "opacity-50"
          )}
        >
          <button className={clsx("flex-0")} onClick={onRemoveUser}>
            <FontAwesomeIcon icon={faXmarkCircle} className="mr-2 text-error" />
          </button>
          <div className="flex-1 text-ellipsis whitespace-nowrap overflow-hidden min-w-0">{`${user.screenName} @${user.slug}`}</div>
          {loading ? (
            <Spinner />
          ) : (
            <FontAwesomeIcon icon={hasToken ? faCheck : faXmark} fixedWidth />
          )}
        </div>
      </TooltipTrigger>
      <TooltipContent>
        {loading
          ? "loading data"
          : hasToken
          ? "user can get notifications"
          : `cannot send notifications to ${user.screenName}`}
      </TooltipContent>
    </Tooltip>
  );
}
