import {
  GqlOps,
  useAdminDeleteUserSetDeletedAtMutation,
  useAdminIsSoftDeletedQuery,
  useAdminRemoveSoftDeleteMutation,
} from "../__generated__/apollo-hooks";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared-web-react/dist/widgets/floating-ui/tooltip";

import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import clsx from "clsx";
import { faBinRecycle } from "@fortawesome/pro-solid-svg-icons";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";

const superAdminContext = { headers: { "x-hasura-role": "super_admin_user" } };

const refetchQueries = [
  GqlOps.Query.AdminUserDetails,
  GqlOps.Query.AdminIsSoftDeleted,
  GqlOps.Query.AdminUserList,
];
export type UserDeleteButtonProps = { userId: string; slug: string };
export function UserSoftDeleteToggle({ userId, slug }: UserDeleteButtonProps): JSX.Element {
  const confirm = useConfirm();
  const addToast = useAddToast();
  const { data, loading } = useAdminIsSoftDeletedQuery({
    variables: { user_id: userId },
    context: superAdminContext,
  });
  console.log("🚀 ~ UserSoftDeleteToggle ~ data:", data);
  const [setDeletedAt] = useAdminDeleteUserSetDeletedAtMutation();
  const [unsetDeletedAt] = useAdminRemoveSoftDeleteMutation();
  const softDeleteUserOnConfirm = React.useCallback(async () => {
    if (loading && !data?.users_by_pk) return;
    if (data?.users_by_pk?.deleted_at) return;
    confirm({
      title: "Soft Delete @" + slug + "?",
      onOk: async () => {
        try {
          await setDeletedAt({
            variables: {
              user_id: userId,
            },
            refetchQueries,
            context: superAdminContext,
          });
          addToast({
            content: "Soft deleted",
            color: "success",
          });
        } catch (err) {
          addToast({
            content: "FAILED TO SOFT DELETE USER. ",
            color: "error",
          });
        }
      },
    });
  }, [loading, data?.users_by_pk, confirm, slug, setDeletedAt, userId, addToast]);
  const deleted_at = data?.users_by_pk?.deleted_at;
  const deletedAtStr = deleted_at
    ? DateTime.fromISO(deleted_at).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
    : null;
  console.log("🚀 ~ UserSoftDeleteToggle ~ deletedAtStr:", deletedAtStr);
  return (
    <Tooltip>
      <TooltipTrigger>
        <TooltipContent>Soft Delete User</TooltipContent>
        <SpinnerButton
          loading={loading}
          onClickWrapped={
            deletedAtStr
              ? () =>
                  unsetDeletedAt({
                    variables: { user_id: userId },
                    refetchQueries,
                    context: superAdminContext,
                  })
              : softDeleteUserOnConfirm
          }
          disabled={loading}
          className={clsx("btn btn-warning ")}
        >
          <span className={clsx("flex items-center gap-2")}>
            <FontAwesomeIcon icon={faBinRecycle} size="2x" />
            {deletedAtStr ? `Un-Soft Delete User deleted at ${deletedAtStr}` : "Soft Delete User"}
          </span>
        </SpinnerButton>
      </TooltipTrigger>
    </Tooltip>
  );
}
